.notification {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;

    .contentTitle {
        margin-bottom: 40px;
        color: black;
    }

    .content {
        width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        height: inherit;



        .row {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 20px 20px 50px;

            h2 {
                color: black;
                margin-right: 40px;
                text-align: center;
                margin: 0 0 20px;
            }

            .updateInput {
                width: 100%;
                display: flex;
                flex-direction: row;
                height: auto;
                min-height: 30px;
                padding: 15px 10px;
                background-color: white;
                border-radius: 50px;
                justify-content: center;
                align-items: center;
                border: 1px solid #474F61;

                .input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    padding: 0 30px;
                    color: black;
                    font-size: 16px;
                    border: none;
                    outline: none;
                    font-size: 1.2rem;

                    &::-webkit-inner-spin-button,
                    ::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                }
            }
        }

        .submit {
            width: 100%;
            border-radius: 50px;
            // background-image: linear-gradient(90deg, #BA2D52, #D93B30);
            background-color: #6268F3;
            border: none;
            color: white;
            font-size: 1.6rem;
            height: 70px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 350px;
            margin: auto;
        }
    }


}