.modal {
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.5);
    top: 0;

    .outside {
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 1;
    }

    .video {
        width: auto;
        height: 100%;
        max-width: 60%;
        max-height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: relative;
        z-index: 2;

        video {
            width: 100%;
            height: 100%;
        }
    }

    .newFaq {

        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        background-color: white;

        .cancel {
            text-align: right;
        }


        textarea,
        input {
            background-color: transparent;
            color: black;
            height: inherit;
            resize: none;
            padding: 10px 20px;
            margin: 5px 0;
            border: 1px solid grey;
            width: 100%;

            &:focus-visible {
                outline: none;
            }
        }

        .save {
            text-align: center;

            button {
                padding: 5px 20px;
                font-size: 1rem;

            }
        }

        .submitFaq {
            display: flex;
            flex-direction: row;


            button {
                margin-left: 10px;
                background-color: transparent;
                border-radius: 100%;
                margin-top: 15px;
                cursor: pointer;

                svg {
                    font-size: 1.5rem;
                    color: white;

                }
            }
        }
    }
}