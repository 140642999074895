.protectedLayout {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;


    aside {
        position: relative;
        background-color: #474F61;
        overflow: hidden;
        display: flex;
        flex-direction: column;


        .children {
            width: 100%;
            height: inherit;
            display: flex;
            flex-direction: column;
            overflow: overlay;

        }

        .logo {
            margin: 30px auto 30px 35px;
            text-align: center;
            cursor: pointer;
        }

        .menu {
            display: flex;
            flex-direction: column;
            overflow: overlay;

            .item {
                margin: 5px 0;
                padding: 18px 35px;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                color: rgb(187, 186, 186);
                font-weight: 600;

                &.active {
                    // background-image: linear-gradient(0deg, #D93B30, #BA2D52);
                    background: transparent linear-gradient(91deg, #6C63FF00 0%, #6C63FF 100%) 0% 0% no-repeat padding-box;
                    color: white;
                }

                p {
                    margin: 0 20px;
                }
            }
        }
    }


    .routeContent {
        position: relative;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;


        .content {
            width: 100%;
            height: -webkit-fill-available;
            display: flex;
            overflow: hidden;

        }

        .Navbar {
            padding: 30px 50px 10px;
            display: flex;
            align-items: center;

            .date {
                margin-right: auto;
                display: flex;
                font-size: 1.4rem;
                color: black;
                justify-content: center;
                align-items: center;

                svg {
                    color: #6C63FF;
                }

                p {
                    margin: 0 14px;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #0A0A0A;
                }
            }

            .search {
                display: flex;
                flex-direction: row;
                width: 20rem;
                height: auto;
                min-height: 30px;
                padding: 3px 7px;
                background-color: white;
                border-radius: 10px;
                border: 1px solid #908FA2;
                justify-content: center;
                align-items: center;

                input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    padding: 0 10px;
                    color: black;
                    font-size: 16px;
                    border: none;
                    outline: none;
                }

                .searchButton {
                    width: 40px;
                    height: 35px;
                    border-radius: 100%;
                    // background-image: linear-gradient(180deg, #BA2D52, #D93B30);
                    background-color: #6268F3;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    cursor: pointer;
                    font-size: 1.3rem;
                }


            }

        }
    }



}