.faqs {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;


    .title {
        display: flex;
        flex-direction: row;
        margin-bottom: 40px;
        align-items: center;

        .contentTitle {
            color: black;
            margin: 0;
            margin-right: 10px;
        }

        button {
            margin-left: 10px;
            background-color: transparent;
            border-radius: 100%;
            margin-top: 15px;
            cursor: pointer;
            border: none;

            svg {
                font-size: 1.5rem;
                color: black;

            }
        }

    }

    .content {
        overflow: overlay;
        width: 100%;
        height: inherit;
        display: flex;
        flex-direction: column;

        button.deleteFaq {
            background-color: transparent;
            cursor: pointer;
            border: none;

            svg {
                color: white;
                font-size: 1rem;
            }
        }

        .newFaq {

            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 10px;
            border: 1px solid grey;

            textarea,
            input {
                background-color: transparent;
                color: black;
                height: inherit;
                resize: none;
                padding: 10px 20px;
                margin: 5px 0;
                border: 1px solid grey;
                width: 100%;

                &:focus-visible {
                    outline: none;
                }
            }

            .submitFaq {
                display: flex;
                flex-direction: row;


                button {
                    margin-left: 10px;
                    background-color: transparent;
                    border-radius: 100%;
                    cursor: pointer;
                    border: none;

                    svg {
                        font-size: 1.5rem;
                        color: black;

                    }
                }
            }
        }
    }

    .space {
        background-color: #474F61;
        margin: 10px 0;
        position: relative;

        .collapse {
            width: 100%;



        }

        span {
            color: white;
            user-select: none;
            font-size: 1.1rem;
            width: -webkit-fill-available;
            margin-right: 90px;

            svg {
                font-size: 1rem;
                margin-top: 6px;
                color: white;
            }

        }

        .edit {
            display: flex;
            position: absolute;
            right: 20px;
            transform: translate(0, -50%);
            top: 53%;
            color: white;
        }

        p {
            color: white;
            font-size: 0.9rem;
            width: 100%;
        }
    }

}